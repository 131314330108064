export default class CookiePreferences {
  constructor(module) {
    this.module = module;

    this.analyticsCookiesYesRadio = this.module.querySelector("#analytics-cookies-yes");
    this.analyticsCookiesNoRadio = this.module.querySelector("#analytics-cookies-no");
    this.saveCookiePreferencesButton = this.module.querySelector("#cookie-preferences__settings-save-button");
    this.cookiePreferencesSavedNotification = this.module.querySelector("#cookie-preferences__notification-banner");
  }

  init() {
    window.FDS.cookieBanner.hideCookieBanner();
    if (window.FDS.cookies.cookiesAllowed()) {
      this.analyticsCookiesYesRadio.checked = true;
    } else {
      this.analyticsCookiesNoRadio.checked = true;
    }
    this.saveCookiePreferencesButton.addEventListener("click", () => this._saveCookiePreferences());
  }

  _saveCookiePreferences() {
    if (this.analyticsCookiesYesRadio.checked) {
      window.FDS.cookies.acceptCookies();
    } else {
      window.FDS.cookies.rejectCookies();
    }
    this.cookiePreferencesSavedNotification.removeAttribute("hidden");
    this.cookiePreferencesSavedNotification.focus();
  }
}

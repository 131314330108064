// Widely used getters for slide out DOM elements
export function getBodyElement() {
  return document.querySelector("body");
}

export function getHtmlElement() {
  return document.querySelector("html");
}

export function getBodyScroll() {
  return document.querySelector("#body-scroll");
}

export function getBodyWrapper() {
  return document.querySelector("#body-wrapper");
}

export function getSlideOutElement() {
  return document.querySelectorAll(".fds-slide-out-panel");
}

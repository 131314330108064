const gaCookieId = "_ga";
export default class GoogleAnalytics {
  constructor(modules) {
    this.modules = modules;
    this.measurementIds = Array.from(this.modules).map(e => e.getAttribute("data-measurement-id"));
  }

  init() {
    this._enableAnalytics();
    window.FDS.cookies.registerRejectCookiesScript(() => { this._disableAnalyticsCookies(); });
  }

  constructMeasurementIdCookieName(measurementId) {
    return `_ga_${measurementId.replace("G-", "")}`;
  }

  _enableAnalytics() {
    const gaScript = document.createElement("script");
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.measurementIds[0]}`;
    document.head.appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];

    this._gtag("js", new Date());

    if (window.FDS.cookies.cookiesAllowed()) {
      this._enableAnalyticsCookies();
    } else {
      this._disableAnalyticsCookies();
    }

    this.measurementIds.forEach(e => {
      this._gtag("config", e);
    });
  }

  _enableAnalyticsCookies() {
    this._gtag("consent", "update", { ad_storage: "denied", analytics_storage: "granted" });
    window.FDS.cookies.registerNonEssentialCookie(gaCookieId);
    this.measurementIds.forEach(measurementId => {
      window.FDS.cookies.registerNonEssentialCookie(this.constructMeasurementIdCookieName(measurementId));
    });
  }

  _disableAnalyticsCookies() {
    function deleteCookie(name, domain) {
      document.cookie = `${name}=;path=/;domain=${domain};Max-Age=-99999999;`;
    }

    const { host } = new URL(window.location.href);

    // GA sets cookie domains at a lower level of specificity than we normally use
    // e.g. fivium.local instead of itportal.dev.fivium.local
    // cookies don't get deleted properly unless we reference them using the correct domain
    // so try deleting with the full hostname and work inwards
    // itportal.dev.fivium.local, then dev.fivium.local etc
    function deleteCookieUsingAllHostnameLevels(cookieName) {
      let domain = host;

      while (domain.indexOf(".") !== -1) {
        deleteCookie(cookieName, domain);
        domain = domain.substring(domain.indexOf(".") + 1);
      }
    }

    this._gtag("consent", "update", { ad_storage: "denied", analytics_storage: "denied" });

    this.measurementIds.forEach(measurementId => {
      this._gtag("config", measurementId, { client_id: "anonymous_user" });
      deleteCookieUsingAllHostnameLevels(this.constructMeasurementIdCookieName(measurementId));
    });

    deleteCookieUsingAllHostnameLevels(gaCookieId);
  }

  _gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}

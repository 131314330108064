import _ from "iframe-resizer";

export default class Example {
  constructor($module) {
    this.$module = $module;
  }

  init() {
    this.resize();
  }

  resize() {
    try {
      // Example iframe; set the height equal to the body height
      _.iframeResizer({ scrolling: "auto", autoResize: true }, this.$module);
    } catch (err) {
      console.error(err.message);
    }
  }
}

/*
*   This software or document includes material copied from or derived from
*   https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/js/datepicker.js.
*   Copyright © 2020 W3C® (MIT, ERCIM, Keio, Beihang).
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   File:   datepicker.js
*/
import CalendarButtonInput from "./calendarButton";
import DatepickerDay from "./datepickerDay";

export default class Datepicker {
  constructor(inputNode, buttonNode, dialogNode) {
    this.dayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    this.monthLabels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.messageCursorKeys = "Cursor keys can navigate dates";
    this.lastMessage = "";

    this.inputNode = inputNode;
    this.buttonNode = buttonNode;
    this.dialogNode = dialogNode;
    this.messageNode = dialogNode.querySelector(".fds-datepicker__message");

    this.dateInput = new CalendarButtonInput(this.inputNode, this.buttonNode, this);

    this.MonthYearNode = this.dialogNode.querySelector(".fds-datepicker__heading");

    this.prevYearNode = this.dialogNode.querySelector(".fds-datepicker__dialog-button--prev-year");
    this.prevMonthNode = this.dialogNode.querySelector(".fds-datepicker__dialog-button--prev-month");
    this.nextMonthNode = this.dialogNode.querySelector(".fds-datepicker__dialog-button--next-month");
    this.nextYearNode = this.dialogNode.querySelector(".fds-datepicker__dialog-button--next-year");

    this.okButtonNode = this.dialogNode.querySelector(".fds-datepicker__button--confirm");
    this.cancelButtonNode = this.dialogNode.querySelector(".fds-datepicker__button--cancel");

    this.tbodyNode = this.dialogNode.querySelector("table.fds-datepicker__date-table tbody");

    this.lastRowNode = null;

    this.days = [];

    this.focusDay = new Date();
    this.selectedDay = new Date(0, 0, 1);

    this.isMouseDownOnBackground = false;

    this.keyCode = Object.freeze({
      TAB: 9,
      ENTER: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
    });
  }

  init() {
    this.dateInput.init();

    this.okButtonNode.addEventListener("click", this.handleOkButton.bind(this));
    this.okButtonNode.addEventListener("keydown", this.handleOkButton.bind(this));

    this.cancelButtonNode.addEventListener("click", this.handleCancelButton.bind(this));
    this.cancelButtonNode.addEventListener("keydown", this.handleCancelButton.bind(this));

    this.prevMonthNode.addEventListener("click", this.handlePreviousMonthButton.bind(this));
    this.nextMonthNode.addEventListener("click", this.handleNextMonthButton.bind(this));
    this.prevYearNode.addEventListener("click", this.handlePreviousYearButton.bind(this));
    this.nextYearNode.addEventListener("click", this.handleNextYearButton.bind(this));

    this.prevMonthNode.addEventListener("keydown", this.handlePreviousMonthButton.bind(this));
    this.nextMonthNode.addEventListener("keydown", this.handleNextMonthButton.bind(this));
    this.prevYearNode.addEventListener("keydown", this.handlePreviousYearButton.bind(this));

    this.nextYearNode.addEventListener("keydown", this.handleNextYearButton.bind(this));

    document.body.addEventListener("mousedown", this.handleBackgroundMouseDown.bind(this), true);
    document.body.addEventListener("mouseup", this.handleBackgroundMouseUp.bind(this), true);

    // Create grid of dates
    this.tbodyNode.innerHTML = "";
    let index = 0;

    // Loop and create calendar rows
    for (let i = 0; i < 6; i++) {
      const tableRow = this.tbodyNode.insertRow(i);
      this.lastRowNode = tableRow;
      tableRow.classList.add("fds-datepicker__date-row");

      // Loop and create calendar cells
      for (let j = 0; j < 7; j++) {
        const tableCell = document.createElement("td");
        tableCell.classList.add("fds-datepicker__date-cell");

        const tableCellButton = document.createElement("button");
        tableCellButton.type = "button";
        tableCellButton.classList.add("fds-datepicker__date-button");

        tableCell.appendChild(tableCellButton);
        tableRow.appendChild(tableCell);

        const dpDay = new DatepickerDay(tableCellButton, this, index, i, j);
        dpDay.init();
        this.days.push(dpDay);

        index += 1;
      }
    }

    this.updateGrid();
    this.setFocusDay();
  }

  updateGrid() {
    let flag;
    const { focusDay } = this;

    this.MonthYearNode.innerHTML = `${this.monthLabels[focusDay.getMonth()]} ${focusDay.getFullYear()}`;

    const firstDayOfMonth = new Date(focusDay.getFullYear(), focusDay.getMonth(), 1);
    const dayOfWeek = firstDayOfMonth.getDay();

    // Make the first day shown in the grid the Monday before the first of the month
    if (firstDayOfMonth.getDate() - dayOfWeek === 1) {
      // First day of the month is a Sunday
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek - 6);
    } else {
      // First day of the month is any other day
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek + 1);
    }

    const day = new Date(firstDayOfMonth);

    Object.keys(this.days).forEach(item => {
      flag = day.getMonth() !== focusDay.getMonth();
      this.days[item].updateDay(flag, day);

      if (day.getFullYear() === this.selectedDay.getFullYear()
        && (day.getMonth() === this.selectedDay.getMonth())
        && (day.getDate() === this.selectedDay.getDate())) {
        this.days[item].domNode.setAttribute("aria-selected", "true");
      }
      day.setDate(day.getDate() + 1);
    });

    this.showLastRow();
  }

  hideLastRow() {
    this.lastRowNode.style.visibility = "hidden";
  }

  showLastRow() {
    this.lastRowNode.style.visibility = "visible";
  }

  setFocusDay(flag = true) {
    const { focusDay } = this;

    function checkDay(day) {
      day.domNode.setAttribute("tabindex", "-1");
      if ((day.day.getDate() === focusDay.getDate())
        && (day.day.getMonth() === focusDay.getMonth())
        && (day.day.getFullYear() === focusDay.getFullYear())) {
        day.domNode.setAttribute("tabindex", "0");
        if (flag) {
          day.domNode.focus();
        }
      }
    }

    this.days.forEach(checkDay.bind(this));
  }

  updateDay(day) {
    const { focusDay } = this;
    this.focusDay = day;
    if ((focusDay.getMonth() !== day.getMonth())
      || (focusDay.getFullYear() !== day.getFullYear())) {
      this.updateGrid();
      this.setFocusDay();
    }
  }

  getDaysInLastMonth() {
    const { focusDay } = this;
    const lastDayOfMonth = new Date(focusDay.getFullYear(), focusDay.getMonth(), 0);
    return lastDayOfMonth.getDate();
  }

  getDaysInMonth() {
    const { focusDay } = this;
    const lastDayOfMonth = new Date(focusDay.getFullYear(), focusDay.getMonth() + 1, 0);
    return lastDayOfMonth.getDate();
  }

  show() {
    this.dialogNode.style.display = "block";
    this.dialogNode.style.zIndex = 2;

    this.getDateInput();
    this.updateGrid();
    this.setFocusDay();
  }

  isOpen() {
    return window.getComputedStyle(this.dialogNode).display !== "none";
  }

  hide() {
    this.setMessage("");

    this.dialogNode.style.display = "none";

    this.hasFocusFlag = false;
    this.dateInput.setFocus();
  }

  handleBackgroundMouseDown(event) {
    if (!this.buttonNode.contains(event.target)
      && !this.dialogNode.contains(event.target)) {
      this.isMouseDownOnBackground = true;

      if (this.isOpen()) {
        this.hide();
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  handleBackgroundMouseUp() {
    this.isMouseDownOnBackground = false;
  }

  handleOkButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:

            this.setTextboxDate();

            this.hide();
            flag = true;
            break;

          case this.keyCode.TAB:
            if (!event.shiftKey) {
              this.prevYearNode.focus();
              flag = true;
            }
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }
        break;

      case "click":
        this.setTextboxDate();
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleCancelButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }
        break;

      case "click":
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleNextYearButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToNextYear();
            this.setFocusDay(false);
            flag = true;
            break;
          default:
            break;
        }

        break;

      case "click":
        this.moveToNextYear();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handlePreviousYearButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToPreviousYear();
            this.setFocusDay(false);
            flag = true;
            break;

          case this.keyCode.TAB:
            if (event.shiftKey) {
              this.okButtonNode.focus();
              flag = true;
            }
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case "click":
        this.moveToPreviousYear();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleNextMonthButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToNextMonth();
            this.setFocusDay(false);
            flag = true;
            break;
          default:
            break;
        }

        break;

      case "click":
        this.moveToNextMonth();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handlePreviousMonthButton(event) {
    let flag = false;

    switch (event.type) {
      case "keydown":

        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToPreviousMonth();
            this.setFocusDay(false);
            flag = true;
            break;
          default:
            break;
        }

        break;

      case "click":
        this.moveToPreviousMonth();
        this.setFocusDay(false);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  moveToNextYear() {
    this.focusDay.setFullYear(this.focusDay.getFullYear() + 1);
    this.updateGrid();
  }

  moveToPreviousYear() {
    this.focusDay.setFullYear(this.focusDay.getFullYear() - 1);
    this.updateGrid();
  }

  moveToNextMonth() {
    this.focusDay.setMonth(this.focusDay.getMonth() + 1);
    this.updateGrid();
  }

  moveToPreviousMonth() {
    this.focusDay.setMonth(this.focusDay.getMonth() - 1);
    this.updateGrid();
  }

  moveFocusToDay(day) {
    const d = this.focusDay;

    this.focusDay = day;

    if ((d.getMonth() !== this.focusDay.getMonth())
      || (d.getYear() !== this.focusDay.getYear())) {
      this.updateGrid();
    }
    this.setFocusDay();
  }

  moveFocusToNextDay() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() + 1);
    this.moveFocusToDay(date);
  }

  moveFocusToNextWeek() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() + 7);
    this.moveFocusToDay(date);
  }

  moveFocusToPreviousDay() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() - 1);
    this.moveFocusToDay(date);
  }

  moveFocusToPreviousWeek() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() - 7);
    this.moveFocusToDay(date);
  }

  moveFocusToFirstDayOfWeek() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() - date.getDay());
    this.moveFocusToDay(date);
  }

  moveFocusToLastDayOfWeek() {
    const date = new Date(this.focusDay);
    date.setDate(date.getDate() + (6 - date.getDay()));
    this.moveFocusToDay(date);
  }

  setTextboxDate(day) {
    if (day) {
      this.dateInput.setDate(day);
    } else {
      this.dateInput.setDate(this.focusDay);
    }
  }

  getDateInput() {
    const parts = this.dateInput.getDate().split("/");

    if ((parts.length === 3)
      && Number.isInteger(parseInt(parts[0]))
      && Number.isInteger(parseInt(parts[1]))
      && Number.isInteger(parseInt(parts[2]))) {
      this.focusDay = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
      this.selectedDay = new Date(this.focusDay);
    } else {
      // If not a valid date (DD/MM/YY) initialize with todays date
      this.focusDay = new Date();
      this.selectedDay = new Date(0, 0, 1);
    }
  }

  getDateForButtonLabel(year, month, day) {
    if (typeof year !== "number" || typeof month !== "number" || typeof day !== "number") {
      this.selectedDay = this.focusDay;
    } else {
      this.selectedDay = new Date(year, month, day);
    }

    let label = this.dayLabels[this.selectedDay.getDay()];
    label += ` ${this.monthLabels[this.selectedDay.getMonth()]}`;
    label += ` ${this.selectedDay.getDate()}`;
    label += `, ${this.selectedDay.getFullYear()}`;
    return label;
  }

  setMessage(str) {
    function setMessageDelayed() {
      this.messageNode.textContent = str;
    }

    if (str !== this.lastMessage) {
      setTimeout(setMessageDelayed.bind(this), 200);
      this.lastMessage = str;
    }
  }
}

/**
 * @param {String} html representing a single element
 * @return {Element}
 */

export default function htmlToElement(html) {
  const template = document.createElement("div");
  html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;

  return template.firstElementChild;
}

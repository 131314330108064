const PREFERENCES_COOKIE_NAME = "cookies-allowed";

export default class Cookies {
  constructor() {
    this.nonEssentialCookies = [];
    this.cookieScripts = [];
    this.rejectCookiesScripts = [];
  }

  registerNonEssentialCookie(name) {
    this.nonEssentialCookies.push(name);
  }

  registerCookieScript(script) {
    this.cookieScripts.push(script);
  }

  registerRejectCookiesScript(script) {
    this.rejectCookiesScripts.push(script);
  }

  runCookieScripts() {
    if (this.cookiesAllowed()) {
      this.cookieScripts.forEach(e => e());
    }
  }

  runRejectCookiesScripts() {
    if (!this.cookiesAllowed()) {
      this.rejectCookiesScripts.forEach(e => e());
    }
  }

  cookiesAllowed() {
    return this.getCookie(PREFERENCES_COOKIE_NAME) === "true";
  }

  prefsCookieSet() {
    const prefsCookieValue = this.getCookie(PREFERENCES_COOKIE_NAME);
    return (prefsCookieValue === "true" || prefsCookieValue === "false");
  }

  acceptCookies() {
    this.setCookie({
      name: PREFERENCES_COOKIE_NAME,
      value: "true",
      days: 365,
    });
    this.runCookieScripts();
  }

  rejectCookies() {
    this.setCookie({
      name: PREFERENCES_COOKIE_NAME,
      value: "false",
      days: 365,
    });
    this.runRejectCookiesScripts();
    this.nonEssentialCookies.forEach(e => this.deleteCookie(e));
  }

  setCookie({ name, value, days }) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

  getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  deleteCookie(name) {
    document.cookie = `${name}=;path=/;Max-Age=-99999999;`;
  }
}

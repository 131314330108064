import escapeHtml from "escape-html";

/**
 * Shared File Upload utils
 * */

export const UploadErrorMessages = Object.freeze({
  EXTENSION: "The selected file must be a",
  MAX_SIZE: "The selected file must be smaller than",
  VIRUS: "The selected file contains a virus",
  UPLOAD: "The selected file could not be uploaded – try again",
});

export const UploadErrorTypes = Object.freeze({
  EXTENSION: "EXTENSION_NOT_ALLOWED",
  MAX_SIZE: "MAX_FILE_SIZE_EXCEEDED",
  VIRUS: "VIRUS_FOUND_IN_FILE",
  SERVER: "INTERNAL_SERVER_ERROR",
});

/**
 * Remove errors that are no longer needed
 * @param container - The file upload container element
 * @param errorId - The file upload input id
 * */
export const removeContainerErrors = (container, errorId) => {
  if (container.querySelector(`#${errorId}-error`)) {
    container.querySelector(`#${errorId}-error`).remove();
    container.classList.remove("govuk-form-group--error");
    container.querySelector(".fds-file-upload-dropzone__link-error").textContent = "";
  }
};

/**
 * Finds the correct DOM error element and inserts error text into it
 * @param element - The file error element
 * @param message - The error message string
 * */
export const showError = (element, message) => {
  const errorHtml = `<span class="govuk-visually-hidden">Error:</span>`;
  element.querySelector(".fds-file-upload-item__error").innerHTML = `${errorHtml} ${escapeHtml(message)}`;
};

export default class ActionDropdown {
  init() {
    $(".fds-action-dropdown__button").click((event) => {
      this.toggleDropdown(event.target);
    });

    const htmlBody = $("body");
    // Hide dropdowns when clicking or focusing anything that's not the currently open dropdown
    htmlBody.on("click", (event) => {
      // If the click isn't on a dropdown or one of its children (one of the list items), hide all dropdowns
      if ($(event.target).closest(".fds-action-dropdown").length === 0) {
        this.hideAll();
        event.stopPropagation();
      }
    });

    htmlBody.on("focus", "*", (event) => {
      // If the element with focus isn't inside the currently expanded dropdown, hide all dropdowns
      if ($(event.target).closest(".fds-action-dropdown").find(".fds-action-dropdown__button[aria-expanded=true]").length === 0) {
        this.hideAll();
        event.stopPropagation();
      }
    });
  }

  toggleDropdown(clickTarget) {
    $(clickTarget).attr("aria-expanded", "true");

    // Position the dropdown
    // Reset position first
    const $ul = $(clickTarget).siblings("ul");
    $ul.css("left", $(clickTarget).css("margin-left")).css("right", "auto");

    $ul.show();

    const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const ulWidth = parseFloat($ul.css("width"));
    const ulLeft = $ul.offset().left;

    // If the dropdown would go off the right edge of the screen, align it to the right edge of the button
    // instead of the left edge
    if (ulWidth + ulLeft > windowWidth) {
      $ul.css("left", "auto").css("right", $(clickTarget).css("margin-right"));
    }

    // Position the dropdown below the bottom of the button
    const newTop = `${$(clickTarget).height()}px`;
    $ul.css("top", newTop);
  }

  hideAll() {
    $(".fds-action-dropdown__button").attr("aria-expanded", "false").siblings("ul").hide();
  }
}

export default class CookieBanner {
  constructor(module) {
    this.module = module;

    this.acceptButton = this.module.querySelector("#cookie-banner__accept");
    this.rejectButton = this.module.querySelector("#cookie-banner__reject");
    this.acceptedHideButton = this.module.querySelector("#cookie-banner__accepted-hide");
    this.rejectedHideButton = this.module.querySelector("#cookie-banner__rejected-hide");

    this.optionsNode = this.module.querySelector("#cookie-banner__options");
    this.acceptedMessageNode = this.module.querySelector("#cookie-banner__accepted-message");
    this.rejectedMessageNode = this.module.querySelector("#cookie-banner__rejected-message");
  }

  init() {
    if (!window.FDS.cookies.prefsCookieSet()) {
      this._showCookieBanner();
    }
  }

  hideCookieBanner() {
    this.module.setAttribute("hidden", "true");
  }

  _showCookieBanner() {
    this.acceptButton.addEventListener("click", () => this._acceptCookies());
    this.rejectButton.addEventListener("click", () => this._rejectCookies());
    this.acceptedHideButton.addEventListener("click", () => this.hideCookieBanner());
    this.rejectedHideButton.addEventListener("click", () => this.hideCookieBanner());
    this.module.removeAttribute("hidden");
  }

  _acceptCookies() {
    this.optionsNode.setAttribute("hidden", "true");
    this.acceptedMessageNode.removeAttribute("hidden");
    this.acceptedMessageNode.focus();
    window.FDS.cookies.acceptCookies();
  }

  _rejectCookies() {
    this.optionsNode.setAttribute("hidden", "true");
    this.rejectedMessageNode.removeAttribute("hidden");
    this.rejectedMessageNode.focus();
    window.FDS.cookies.rejectCookies();
  }
}

import "blueimp-file-upload";
import select2 from "select2/dist/js/select2.full";
import StickyFill from "stickyfilljs";
import hljs from "highlight.js/lib/core";
import hljsCss from "highlight.js/lib/languages/css";
import hljsJava from "highlight.js/lib/languages/java";
import hljsJavascript from "highlight.js/lib/languages/javascript";
import hljsJson from "highlight.js/lib/languages/json";
import hljsKotlin from "highlight.js/lib/languages/kotlin";
import hljsMarkdown from "highlight.js/lib/languages/markdown";
import hljsPlaintext from "highlight.js/lib/languages/plaintext";
import hljsScss from "highlight.js/lib/languages/scss";
import hljsShell from "highlight.js/lib/languages/shell";
import hljsSql from "highlight.js/lib/languages/sql";
import hljsTypescript from "highlight.js/lib/languages/typescript";
import hljsXml from "highlight.js/lib/languages/xml";
import hljsYaml from "highlight.js/lib/languages/yaml";
import CodeSampleTabs from "./components/codeSample/tabs";
import SlideOutManager from "./components/slideOutPanel/slideOutManager";
import SearchSelector from "./components/searchSelector/searchSelector";
import SearchFilters from "./patterns/search/searchFilters";
import ShowPassword from "./components/password/password";
import Modal from "./components/modal/modal";
import FileUpload from "./components/fileUpload/fileUpload";
import Example from "./components/codeSample/example";
import Datepicker from "./components/datepicker/datepicker";
import BackToTop from "./components/backToTop/backToTop";
import ActionDropdown from "./components/actionDropdown/actionDropdown";
import AddToList from "./components/addToList/addToList";
import AddAField from "./components/addAField/addAField";
import CookieBanner from "./components/cookieBanner/cookieBanner";
import Cookies from "./js/cookies";
import GoogleAnalytics from "./components/googleAnalytics/googleAnalytics";
import CookiePreferences from "./components/cookiePreferences/cookiePreferences";
import { FdsHeader } from "./components/header/fdsHeader.mjs";
import Form from "./components/form/form";

// Init in the FDS namespace
// When the presence of a component is always needed
const modal = new Modal();
const addToList = new AddToList();
const cookies = new Cookies();
const slideOutManager = new SlideOutManager();
window.FDS = {
  modal, addToList, cookies, slideOutManager,
};

// Run any cookie scripts if needed/allowed
window.FDS.cookies.runCookieScripts();

// Init header
const header = document.querySelector("[data-module='fds-header']");
if (header != null) {
  new FdsHeader(header)
}

// Init action dropdown
const actionDropdown = document.querySelector("[data-module='fds-action-dropdown']");
if (actionDropdown != null) {
  new ActionDropdown().init();
}

// Init add a field
const addAField = document.querySelectorAll("[data-module='fds-add-a-field']");
addAField.forEach(module => new AddAField(module));

// Init back to top
const backToTop = document.querySelector("[data-module='fds-back-to-top']");
if (backToTop != null) {
  new BackToTop(backToTop).init();
}

// Init form(s)
const forms = document.querySelectorAll("[data-module='fds-html-form']");
forms.forEach(form => new Form(form).init());

// Initialize menu button date picker
const datepickers = document.querySelectorAll("[data-module='fds-datepicker']");
datepickers.forEach((dp) => {
  const inputNode = dp.querySelector("input");
  const buttonNode = dp.querySelector("button");
  const dialogNode = dp.querySelector("[role=dialog]");

  const datepicker = new Datepicker(inputNode, buttonNode, dialogNode);
  datepicker.init();
});

const examples = document.querySelectorAll("[data-module='fds-example-frame']");
examples.forEach((example) => new Example(example).init());

// Init file upload
const fileUpload = document.querySelectorAll("[data-module='fds-file-upload']");
fileUpload.forEach((element) => new FileUpload(element));

// Register any cookie modules (GA etc)
const googleAnalytics = document.querySelectorAll("[data-module='fds-google-analytics']");
if (googleAnalytics.length > 0) {
  window.FDS.googleAnalytics = new GoogleAnalytics(googleAnalytics);
  window.FDS.googleAnalytics.init();
}

// Initialise cookie banner
const cookieBanner = document.querySelector("[data-module='fds-cookie-banner']");
if (cookieBanner != null) {
  window.FDS.cookieBanner = new CookieBanner(cookieBanner);
  window.FDS.cookieBanner.init();
}

// Initialise cookie preferences
const cookiePreferences = document.querySelector("[data-module='fds-cookie-preferences']");
if (cookiePreferences != null) {
  new CookiePreferences(cookiePreferences).init();
}

// Init search filters
const searchFilters = document.querySelector("[data-module='fds-search-filters']");
if (searchFilters != null) {
  new SearchFilters(searchFilters).init();
}

// Init search selector(s)
select2($); // Select2 can't bind to the bundled jQuery automatically
const searchSelectors = document.querySelectorAll("[data-module='fds-search-selector']");
searchSelectors.forEach(searchSelector => {
  new SearchSelector(searchSelector).init();
});

// Init show password
const showPassword = document.querySelector("[data-module='fds-password']");
if (showPassword != null) {
  new ShowPassword(showPassword).init();
}

const stickySubnav = document.querySelector("[data-module='fds-subnav-sticky']");
if (stickySubnav != null) {
  StickyFill.addOne(stickySubnav);
}

// Init code sample tabs
const tabs = document.querySelectorAll("[data-module='fds-code-sample-tabs']");
tabs.forEach(tab => new CodeSampleTabs(tab).init());

// Init highlight.js
const hljsLanguages = {
  css: hljsCss,
  freemarker: hljsXml,
  java: hljsJava,
  javascript: hljsJavascript,
  json: hljsJson,
  kotlin: hljsKotlin,
  markdown: hljsMarkdown,
  plaintext: hljsPlaintext,
  scss: hljsScss,
  shell: hljsShell,
  sql: hljsSql,
  typescript: hljsTypescript,
  xml: hljsXml,
  yaml: hljsYaml,
};

Object.keys(hljsLanguages).forEach(key => hljs.registerLanguage(key, hljsLanguages[key]));

hljs.highlightAll();

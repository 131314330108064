import SlideOutPanel from "./slideOutPanel.js";
import htmlToElement from "../../js/htmlToElement";
import {
  getBodyWrapper, getBodyScroll, getBodyElement, getSlideOutElement,
} from "./slideOutUtil";

export default class SlideOutManager {
  constructor() {
    this.panels = [];
    this.activePanel = null;

    this.initPanels();

    // Load panel specified in fragment identifier, if it exists
    const searchParams = new URLSearchParams(window.location.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const [panelId, panelActive] of searchParams.entries()) {
      if (panelActive && this.panelExists(panelId)) {
        getBodyScroll().style.transition = "none";
        this.managePanel(panelId);
      }
    }

    // Set up event handlers to make slide out panel triggers show their respective panels
    document.querySelectorAll("*[data-open-slide-out-panel]").forEach((element) => {
      element.addEventListener("click", (event) => {
        const targetPanelId = event.target.getAttribute("data-open-slide-out-panel");
        this.managePanel(targetPanelId);
        event.stopPropagation();
        event.preventDefault();
      });
    });

    document.querySelectorAll("*[data-close-slide-out-panel]").forEach((element) => {
      element.addEventListener("click", (event) => {
        this.hideActivePanel();
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  initPanels() {
    // Move the whole contents of the body into a container,
    // so we can get the panels and content to scroll independently
    if (getBodyWrapper() == null) {
      const detachedBody = document.querySelectorAll("body>*");
      const bodyScrollWrapperDivs = htmlToElement(
        `<div id="body-scroll" class="fds-body-scroll"><div id="body-wrapper"></div></div>`,
      );

      detachedBody.forEach((element) => {
        bodyScrollWrapperDivs.querySelector("#body-wrapper").appendChild(element);
      });
      getBodyElement().appendChild(bodyScrollWrapperDivs);
    }

    getSlideOutElement().forEach((panelDiv) => {
      this.createPanel(panelDiv.getAttribute("id"));
      getBodyElement().appendChild(panelDiv);
    });
  }

  createPanel(panelId) {
    this.panels[panelId] = new SlideOutPanel(panelId);
  }

  managePanel(panelId) {
    // Check panel exists
    if (!this.panelExists(panelId)) return;

    // Check it's not the current panel
    if (this.panels[panelId] === this.activePanel) return;

    if (this.activePanel) {
      // If there's an active panel, hide it.
      // Pass showPanel() in a callback function that's run when the other panel has finished hiding
      this.activePanel.hidePanel(() => this.panels[panelId].showPanel());
    } else {
      // If there's no active panel just show this one straight away
      this.panels[panelId].showPanel();
    }

    this.activePanel = this.panels[panelId];
  }

  hideActivePanel() {
    this.activePanel.hidePanel();
    this.activePanel = null;
  }

  panelExists(panelId) {
    return Object.prototype.hasOwnProperty.call(this.panels, panelId);
  }
}

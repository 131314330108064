import escapeHtml from "escape-html";
import announce from "../../js/accessibility";

export default class AddToList {
  constructor() {
    this.itemLinkAttribute = "a[data-add-to-list-item-id]";

    this.init();
  }

  init() {
    $("body").on("click", this.itemLinkAttribute, (event) => {
      const $link = $(event.target);

      event.preventDefault();
      this._removeFromList($link.attr("data-add-to-list-item-id"), $link.attr("data-add-to-list-instance-id"));
    });
  }

  // Remove item from list and hidden input
  _removeFromList(id, listId) {
    const lastInList = this._isLastItemInList(listId);
    this._removeFromInput(id, listId);
    const cleanedId = this._removeSlashesFromId(id); // The targetable id will have no slashes
    const text = $(`#${cleanedId}-text`).text();

    $(`#${cleanedId}`).remove();

    if (lastInList) {
      this._toggleInsetText(listId);
    }

    announce(`${text} removed from list`);
  }

  // Add a row to the specified table with a row id of data.id and the text showing data.text
  _addRowToList(addToListId, data, invalidItemText) {
    this._addToInput(addToListId, data.id);
    const id = this._removeSlashesFromId(data.id);// For ids targeted by jquery remove '/' characters

    // Show the list if it's hidden and hide the inset text
    if ($(`#${addToListId}`).is(":hidden")) {
      this._toggleInsetText(addToListId);
    }

    const errorMessage = data.isValid === "false"
      ? `<p class="govuk-error-message govuk-error-message--inline">
          <span class="govuk-visually-hidden">Error:</span> ${invalidItemText}
        </p>`
      : "";
    const visuallyHiddenText = data.isValid === "false"
      ? `${data.text} ${invalidItemText}`
      : data.text;

    const tableRow = `
      <tr class="govuk-table__row" id="${id}">
        <td class="govuk-table__cell" id="${id}-text">
          ${escapeHtml(data.text)}
          ${errorMessage}
        </td>
        <td class="govuk-table__cell govuk-table__cell--actions">
          <a href="#${addToListId}"
             data-add-to-list-item-id="${data.id}"
             data-add-to-list-instance-id="${addToListId}"
             class="govuk-link">
            Remove<span class="govuk-visually-hidden"> ${escapeHtml(visuallyHiddenText)}</span>
          </a>
        </td>
      </tr>`;

    $(`#${addToListId} > tbody:last-child`).append(tableRow);

    announce(`${data.text} added to list`);
  }

  _isLastItemInList(listId) {
    return $(`#${listId} > tbody`).find("tr").length === 1;
  }

  // Show / hide the table and the inset text depending on their current state
  _toggleInsetText(listId) {
    $(`#${listId}`).toggle(); // list
    $(`#${listId}-inset`).toggle(); // inset
  }

  // update the input value to include the new id
  _addToInput(inputId, data) {
    const val = this._getInput(inputId).val();
    const newValue = val === "" ? data : `${val},${data}`;
    this._getInput(inputId).val(newValue);
  }

  // update the input value to remove the provided id
  _removeFromInput(id, inputId) {
    const list = this._getInput(inputId).val();
    this._getInput(inputId).val(this._removeStringFromList(list, id));
  }

  // If the value is the only item in the list remove it
  // If the value is the first item in the list remove it and the following comma
  // If it's not remove it and the preceding comma
  _removeStringFromList(list, string) {
    if (list === string) {
      return "";
    } else {
      return list.replace(list.startsWith(`${string},`) ? `${string},` : `,${string}`, "");
    }
  }

  // Jquery selector to get the input
  // The input lives inside a div with id {inputId}-input
  _getInput(inputId) {
    return $(`#${inputId}-input > input`);
  }

  _removeSlashesFromId(id) {
    return id.replace("/", "-");
  }
}

export default class SearchSelector {
  constructor(module) {
    this.module = module;
  }

  init() {
    const selectorId = this.module.id;

    const select2Opts = {
      containerCssClass: `${selectorId}-container`,
    };

    const sortingMethod = this.module.getAttribute("data-sorting-method");
    if (sortingMethod === "prioritiseStart") {
      // Prioritise results which have matches at the start of words (split based on whitespace/hyphens).
      // The order is left unchanged for results which have no matches at the start of words.
      // When there are multiple prioritised results, they are ordered based on the first word matched,
      // with results that match on earlier words appearing higher in the list.
      select2Opts.sorter = data => {
        const searchQuery = $(".select2-search__field").val().toLowerCase();

        const getFirstWordMatchIndex = result => {
          const words = result.text.split(/[ -]+/);
          return words.findIndex(word => word.toLowerCase().startsWith(searchQuery));
        };

        data.sort((result1, result2) => {
          const result1FirstWordMatchIndex = getFirstWordMatchIndex(result1);
          const result2FirstWordMatchIndex = getFirstWordMatchIndex(result2);

          // Don't prioritise results which have no matches
          if (result1FirstWordMatchIndex === -1 && result2FirstWordMatchIndex === -1) {
            return 0;
          } else if (result1FirstWordMatchIndex === -1) {
            return 1;
          } else if (result2FirstWordMatchIndex === -1) {
            return -1;
          }

          // Sort ascending based on the index of the first matched word
          return result1FirstWordMatchIndex - result2FirstWordMatchIndex;
        });
        return data;
      };
    }

    const hasError = this.module.getAttribute("data-selector-has-error");
    if (hasError === "true") {
      select2Opts.containerCssClass += " fds-search-selector--error";
    }

    const restUrl = this.module.getAttribute("data-selector-rest-url");
    let requestDelay = this.module.getAttribute("data-selector-request-delay");
    if (requestDelay === null || requestDelay === "") {
      requestDelay = 0;
    }
    if (restUrl !== null) {
      select2Opts.ajax = {
        url: restUrl,
        dataType: "json",
        processResults: SearchSelector._ariaAwareProcessResultsHandler,
        delay: requestDelay,
      };
    }

    const minInputLength = this.module.getAttribute("data-selector-min-input-length");
    if (minInputLength !== null) {
      select2Opts.minimumInputLength = minInputLength;
    }

    // Get classes before select2 initialisation as select2 modifies the attr
    const selectorClass = this.module.getAttribute("class");

    $(this.module).select2(select2Opts);

    // Apply any additional classes present on the underlying select onto the wrapping span
    if (selectorClass !== null) {
      $(this.module).data("select2").$container.addClass(selectorClass);
    }

    this._focusClickHandler();
    this._registerKeydownHandler();

    this._registerAddToListListener();

    const multiSelect = this.module.getAttribute("multiple");
    if (multiSelect !== null) {
      this._setAriaLabelMulti();
      // Trigger an update of the label as the selector may be pre-populated
      SearchSelector._updateMultiSelectAriaLabel(this.module);
    } else {
      this._setAriaLabelSingle();
      this._registerClearLink();
      this._replaceArrow();
    }
    this._setAriaDescribedBy();
  }

  // If the add to list settings are present set the event listener for selections
  _registerAddToListListener() {
    // Find out if we're adding to a list
    const addToListInit = this.module.getAttribute("data-add-to-list");
    const addToListId = this.module.getAttribute("data-add-to-list-id");
    if (addToListInit && addToListId !== null) {
      // Set listener if we are
      $(this.module).on("select2:select", () => { this._updateAddToList(this.module, addToListId); });
    }
  }

  // jQuery 3.6.1 upgrade regression - input focus in the select2 core code does not trigger
  // We handle it here instead
  _focusClickHandler() {
    $(document).on("select2:open", () => {
      document.querySelector(".select2-container--open .select2-search__field").focus();
    });
  }

  // Open the selector on keydown if it has focus. Capture backspace to improve UX
  _registerKeydownHandler() {
    $(`.${this.module.id}-container`).keydown(e => {
      if (e.which === 8) {
        // Prevent a focused backspace triggering a browser back
        e.preventDefault();
      } else if (e.which >= 32) {
        $(this.module).select2("open"); // Open the widget
        // jQuery 3.6.1 upgrade regression - input focus in the select2 core code does not trigger
        // Manual DOM focus instead of using select2 input properties to select from
        document.querySelector(".select2-container--open .select2-search__field").focus(); // Focus the search text input
      }
    });
  }

  _setAriaLabelSingle() {
    $(`.${this.module.id}-container`).attr("aria-labelledby", (i, val) => `selector-${this.module.id}-label ${val}`);
  }

  _setAriaDescribedBy() {
    if ($(`#${this.module.id}-hint`).length > 0) {
      $(`.${this.module.id}-container`).attr("aria-describedby", `${this.module.id}-hint`);
    }
  }

  _setAriaLabelMulti() {
    $(`.${this.module.id}-container .select2-search__field`)
      .attr("aria-labelledby", `selector-${this.module.id}-label selector-${this.module.id}-aria`);

    // Update aria text when selected values change
    $(this.module).on("select2:select select2:unselect", () => { SearchSelector._updateMultiSelectAriaLabel(this.module); });
  }

  _registerClearLink() {
    $(`#selector-${this.module.id}-clear`).click(() => {
      $(this.module).val(null).trigger("change");
      return false;
    });
  }

  // Add to the selection to the provided list component.
  _updateAddToList(module, addToListId) {
    const selection = $(module).select2("data");
    // Add row
    window.FDS.addToList._addRowToList(addToListId, selection[0]);
    // clear value from selector
    $(module).val(null).trigger("change");
  }

  _replaceArrow() {
    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" aria-hidden="true" focusable="false">
        <path fill="currentColor" d="M0 0h8L4 5z"></path>
    </svg>
    `;

    $(`.${this.module.id}-container .select2-selection__arrow b`).replaceWith(svg);
  }

  static _ariaAwareProcessResultsHandler(data) {
    // Important. Need to provide a _resultId prop to select2 to ensure it correctly applies and updated
    // the aria-activedescendant item on the input field
    data.results.forEach(item => {
      item._resultId = item.id;
    });
    return data;
  }

  // method is static as the correct `this` context is lost when invoked as an event handler
  static _updateMultiSelectAriaLabel(module) {
    const $ariaElem = $(`#selector-${module.id}-aria`);
    $ariaElem.empty();

    const $items = $(module).select2("data");
    if ($items.length === 0) {
      $ariaElem.text("no items selected");
    } else {
      $ariaElem.text(`${$items.length} items selected: `);
      $items.forEach($item => {
        $ariaElem.append(`${$item.text}, `);
      });
    }
  }
}

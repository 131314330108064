const div = document.createElement("div");

export default function announce(text) {
  div.textContent = text;
}

$(document).ready(() => {
  div.classList.add("screen-reader-only");
  div.classList.add("govuk-visually-hidden");
  div.setAttribute("aria-live", "polite");
  div.setAttribute("aria-atomic", "true");
  document.body.appendChild(div);
});

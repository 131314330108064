export default class SearchFilters {
  constructor($module) {
    this.$module = $module;
  }

  init() {
    const headingItemSpan = $('span.fds-search-filters__item-heading-button');

    // Replace spans with buttons for expanding functionality
    headingItemSpan.each((id, element) => {
      const headingItemButton = `<button 
        type="button" 
        class="fds-search-filters__item-heading-button fds-link-button"
        id="${element.id}"
        aria-expanded="false" 
        aria-controls="${$(element).attr('data-aria-controls')}">${element.innerHTML}</button>`

      $(element).replaceWith(headingItemButton);
    })

    // When the heading buttons are clicked, toggle the section visibility
    $('.fds-search-filters__item-heading-button').on('click', (event) => {
      if($(event.target).attr('aria-expanded') === 'false') {
        $(event.target).attr('aria-expanded', 'true');
      } else {
        $(event.target).attr('aria-expanded', 'false');
      }

      $(event.target).toggleClass('fds-search-filters__item-heading-button--collapsed');
      $(event.target).closest('.fds-search-filters__item').find('.fds-search-filters__item-component').toggleClass('fds-search-filters__item-component--visible');
    })

    // Look for the visible element and set aria and button class on load
    $('.fds-search-filters__item-component').each((id, element) => {
      const itemButton = $(element).closest('.fds-search-filters__item').find('button.fds-search-filters__item-heading-button');
      const checkboxHasValue = $(element).find('.govuk-checkboxes__input, .govuk-radios__input').is(':checked');
      const textInputHasValue = $(element).find('.govuk-input').val();

      if(!$(element).hasClass('fds-search-filters__item-component--visible')) {
        if(checkboxHasValue || textInputHasValue) {
          this.openSelectedFilters(element);
        } else {
          itemButton.addClass('fds-search-filters__item-heading-button--collapsed')
          itemButton.attr('aria-expanded', 'false');
        }

      } else {
        itemButton.attr('aria-expanded', 'true');
      }
    })

    this.toggleMobileFilters();
  }

  // Enable the show/hide filters button for mobile
  toggleMobileFilters() {
    $('.fds-search-filters__toggle-mobile').on('click', (event) => {
      if ($(event.target).attr('aria-expanded') === 'false') {
        $(event.target).attr('aria-expanded', 'true').text('Hide filters');
        $('.fds-search-filters').addClass('fds-search-filters--visible');
      } else {
        $(event.target).attr('aria-expanded', 'false').text('Show filters');
        $('.fds-search-filters').removeClass('fds-search-filters--visible');
      }
    });
  }

  // Toggle open any sections with selected checkboxes or radios, or inputs with values
  openSelectedFilters(element) {
    $(element)
      .closest('.fds-search-filters__item')
      .find('.fds-search-filters__item-heading-button')
      .click();
  }
}
const tabsItemClass = "fds-code-sample-tabs__item";
const tabsItemCurrentClass = `${tabsItemClass}--current`;
const tabsItemJsClass = "js-tabs__item";
const headingItemClass = "fds-code-sample-tabs__heading";
const headingItemCurrentClass = `${headingItemClass}--current`;
const headingItemJsClass = "js-tabs__heading";
const tabContainerHiddenClass = "fds-code-sample-tabs__container--hidden";
const tabContainerJsClass = ".js-tabs__container";
const tabContainerNoTabsJsClass = "js-tabs__container--no-tabs";
const allTabTogglers = `.${tabsItemJsClass} a, .${headingItemJsClass} a`;
const tabTogglersMarkedOpenClass = ".js-tabs__item--open a";

class ExampleTabs {
  constructor($module) {
    this.$module = $module;
    this.$allTabContainers = this.$module.querySelectorAll(tabContainerJsClass);
    this.$allTabTogglers = this.$module.querySelectorAll(allTabTogglers);
    this.$allTabTogglersMarkedOpen = this.$module.querySelectorAll(tabTogglersMarkedOpenClass);
  }

  init() {
    if (!this.$module) return;

    // reset all tabs
    this.resetTabs();
    // add close to each tab
    this.$module.addEventListener("click", this.handleClick.bind(this));

    this.$allTabTogglersMarkedOpen.forEach($tabToggler => $tabToggler.click());
  }

  // expand and collapse functionality
  activateAndToggle(event) {
    event.preventDefault();
    const $currentToggler = event.target;
    const $currentTogglerSiblings = this.$module.querySelectorAll(`[href="${$currentToggler.hash}"]`);
    let $tabContainer;

    try {
      $tabContainer = this.$module.querySelector($currentToggler.hash);
    } catch (exception) {
      throw new Error(`Invalid example ID given: ${exception}`);
    }

    if (!$tabContainer) return;

    const isTabAlreadyOpen = $currentToggler.getAttribute("aria-expanded") === "true";

    if (isTabAlreadyOpen) {
      $tabContainer.classList.add(tabContainerHiddenClass);
      $tabContainer.setAttribute("aria-hidden", "true");

      $currentTogglerSiblings.forEach($tabToggler => {
        $tabToggler.setAttribute("aria-expanded", "false");
        // desktop and mobile
        $tabToggler.parentNode.classList.remove(tabsItemCurrentClass, headingItemCurrentClass);
      });
    } else {
      // Reset Tabs
      this.resetTabs();

      // make current active
      $tabContainer.classList.remove(tabContainerHiddenClass);
      $tabContainer.setAttribute("aria-hidden", "false");

      $currentTogglerSiblings.forEach($tabToggler => {
        $tabToggler.setAttribute("aria-expanded", "true");

        if ($tabToggler.parentNode.classList.contains(tabsItemClass)) {
          $tabToggler.parentNode.classList.add(tabsItemCurrentClass);
        } else if ($tabToggler.parentNode.classList.contains(headingItemClass)) {
          $tabToggler.parentNode.classList.add(headingItemCurrentClass);
        }
      });
    }
  }

  // reset aria attributes to default and close the tab content container
  resetTabs() {
    this.$allTabContainers.forEach($tabContainer => {
      // unless the tab content has not tabs and it's been set as open
      if (!$tabContainer.classList.contains(tabContainerNoTabsJsClass)) {
        $tabContainer.classList.add(tabContainerHiddenClass);
        $tabContainer.setAttribute("aria-hidden", "true");
      }
    });

    this.$allTabTogglers.forEach($tabToggler => {
      $tabToggler.setAttribute("aria-expanded", "false");
      // desktop and mobile
      $tabToggler.parentNode.classList.remove(tabsItemCurrentClass, headingItemCurrentClass);
    });
  }

  handleClick(event) {
    // toggle and active selected tab and heading (on mobile)
    if (event.target.parentNode.classList.contains(tabsItemJsClass)
      || event.target.parentNode.classList.contains(headingItemJsClass)) {
      this.activateAndToggle(event);
    }
  }
}

export default ExampleTabs;

import "element-remove";
import escapeHtml from "escape-html";
import announce from "../../js/accessibility";

export default class AddAField {
  constructor(module) {
    this.module = module;
    this.fieldId = this.getFirstFieldId();
    this.fieldLabel = this.module.getAttribute("data-add-field-label");
    this.fieldHint = this.module.getAttribute("data-add-field-hint");
    this.fieldInputClass = this.module.getAttribute("data-add-field-input-class");
    this.optionalLabel = this.module.getAttribute("data-add-field-optional");

    this.addFieldHandler();
    this.currentFieldsRemoveHandler();
    this.setupScreenReaderText();
  }

  addFieldHandler() {
    this.module.querySelector(".fds-add-a-field__link--add")
      .addEventListener("click", event => this.addFieldCallback(event));
  }

  currentFieldsRemoveHandler() {
    const removeActions = this.module.querySelectorAll(".fds-add-a-field__link--remove");
    removeActions.forEach(element => {
      element.addEventListener("click", event => this.removeFieldCallback(event));
    });
  }

  // if a new field is added, add a handler to the last remove action added
  newFieldRemoveHandler() {
    const lastItem = this.module.querySelector(".fds-add-a-field__item:last-of-type .fds-add-a-field__link--remove");
    lastItem.addEventListener("click", event => this.removeFieldCallback(event));
  }

  addFieldCallback(event) {
    this.addFieldHtml();
    this.newFieldRemoveHandler();
    event.preventDefault();
  }

  removeFieldCallback(event) {
    this.removeField(event.target);
    this.removeSectionBreak();
    event.preventDefault();
  }

  removeSectionBreak() {
    this.module.querySelectorAll(".fds-add-a-field__link--remove")
      .forEach((link, index) => {
        const sectionBreak = this.module.querySelector(".fds-add-a-field__item:first-of-type hr");
        if (index === 0 && sectionBreak !== null) {
          sectionBreak.remove();
        }
      });
  }

  addFieldHtml() {
    const index = this.module.querySelectorAll(".fds-add-a-field__item").length;
    const id = this.fieldId.replace(/\d+$/, "");

    // TODO add back hyphen to id, hint, aria FDS-424
    const hintTextHtml = `<div id="${id}${index}-hint" class="govuk-hint">${escapeHtml(this.fieldHint)}</div>`;
    const hintText = this.fieldHint ? hintTextHtml : "";
    const ariaDescribedBy = this.fieldHint ? `aria-describedby="${id}${index}-hint"` : "";
    const labelText = `${this.fieldLabel} ${this.isLabelOptional(this.optionalLabel)}`;
    const sectionBreak = index === 0
      ? ""
      : `<hr class="govuk-section-break govuk-section-break--m govuk-section-break--visible">`;

    // TODO FDS-417 Add prefix, suffix, autocomplete, spellcheck, maxlength and other attributes

    const fieldHtml = `
      <div class="fds-add-a-field__item">
        ${sectionBreak}
        <div class="govuk-form-group">
          <div class="fds-add-a-field__item-context"> 
            <label class="govuk-label" for="${id}${index}">
            ${escapeHtml(labelText)} <span class="govuk-visually-hidden">${index + 1}</span>
            </label>
            <a href="#" class="govuk-link fds-add-a-field__link fds-add-a-field__link--remove" role=true>
              Remove
              <span class="govuk-visually-hidden">${escapeHtml(`${this.fieldLabel} ${index + 1}`)}</span>
            </a>
          </div>
          ${hintText}
          <input
            class="govuk-input ${this.fieldInputClass}"
            type="text"
            id="${id}${index}"
            name="${id}[${index}]"
            ${ariaDescribedBy}
            maxlength="4000">
        </div>
      </div>`;

    this.module.querySelector(".fds-add-a-field__link--add").insertAdjacentHTML("beforebegin", fieldHtml);
    this.module.querySelector(".fds-add-a-field__item:last-of-type .govuk-input").focus();
  }

  removeField(target) {
    const fieldListItem = target.closest(".fds-add-a-field__item");
    const labelText = fieldListItem.querySelector(".govuk-label").textContent;

    fieldListItem.remove();
    announce(`${labelText} removed`);

    this.module.querySelectorAll(".fds-add-a-field__item")
      .forEach((element, index) => {
        element.querySelector(".govuk-label").innerHTML = `${escapeHtml(
          `${this.fieldLabel} ${this.isLabelOptional(this.optionalLabel)}`,
        )} <span class="govuk-visually-hidden">${index + 1}</span>`;
      });

    // Check if the target input is not in error
    const inputInError = fieldListItem.querySelector(".govuk-input--error");

    if (inputInError) {
      const removedInputId = inputInError.getAttribute("id");
      // Find all error summary links for elements we have removed
      document.querySelector(`.govuk-error-summary__list li a[href="#${removedInputId}"]`).closest("li").remove();

      // If it's empty remove the complete summary element
      if (document.querySelectorAll(".govuk-error-summary__list li").length === 0) {
        document.querySelector(".govuk-error-summary").remove();
      }
    }

    // If the last field is removed, add a new one
    if (this.module.querySelectorAll(".fds-add-a-field__item").length === 0) {
      this.addFieldHtml();
      this.newFieldRemoveHandler();
    }

    const lastFieldInList = this.module.querySelector(".fds-add-a-field__item:last-of-type .govuk-input");
    if (lastFieldInList !== null) {
      lastFieldInList.focus();
    }
  }

  setupScreenReaderText() {
    if (document.querySelector(".govuk-error-summary") !== null) {
      const summaryLinks = document.querySelectorAll(".govuk-error-summary__list li a");
      const errorMessages = this.module.querySelectorAll(".govuk-input--error");

      const ids = [...errorMessages].map(element => element.getAttribute("id"));
      const links = [...summaryLinks]
        .filter(element => ids.includes(element.getAttribute("href").replace("#", "")));

      links.forEach(element => {
        const attr = element.getAttribute("href");
        element.insertAdjacentHTML("beforeend", this.screenReaderErrorText(attr));
      });

      errorMessages.forEach(element => {
        const attr = element.getAttribute("id");
        element.previousElementSibling.insertAdjacentHTML("beforeend", this.screenReaderErrorText(attr));
      });
    }
  }

  screenReaderErrorText(attribute) {
    return `<span class="govuk-visually-hidden"> ${parseInt(attribute.match("\\d+$")) + 1}</span>`;
  }

  isLabelOptional(optional) {
    return optional === "true" ? " (optional)" : "";
  }

  getFirstFieldId() {
    const input = this.module.querySelector(".fds-add-a-field__item .govuk-input");
    return input.getAttribute("id");
  }
}

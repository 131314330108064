export default class Form {
  constructor($module) {
    this.$module = $module;
    this.disableAllSubmitButtons = document.querySelector("[data-module='fds-disable-on-submit']");
    this.disabledButtons = this.$module.querySelectorAll("[data-fds-disable-on-submit]");
  }

  disableSubmitButtons() {
    // setTimeout used to ensure the buttons are disabled after form submission due to form.submit() queuing the action
    // instead of immediately performing it
    setTimeout(() => {
      this.findSubmitButton().setAttribute("disabled", true);
    });
  }

  onPageShow() {
    window.addEventListener("pageshow", () => {
      this.findSubmitButton().removeAttribute("disabled");
    });
  }

  findSubmitButton() {
    return this.$module.querySelector("[data-fds-disable-on-submit=\"true\"]");
  }

  init() {
    if (this.disableAllSubmitButtons) {
      const buttonAttr = "data-fds-disable-on-submit";

      [...this.disabledButtons].forEach(button => {
        if (button.getAttribute(buttonAttr) === "false") {
          button.setAttribute(buttonAttr, "true");
        }
      });
    }

    if (this.findSubmitButton()) {
      this.onPageShow();
      this.$module.addEventListener("submit", this.disableSubmitButtons.bind(this));
    }
  }
}

// Slide out class
import { getHtmlElement, getBodyScroll } from "./slideOutUtil.js";

export default class SlideOutPanel {
  constructor(panelId) {
    this.panelId = panelId;
    this.panelSelector = document.querySelector(`#${this.panelId}`);
    this.panelVisible = false;

    getHtmlElement().classList.add("fds-slide-out-initialised");
  }

  showPanel() {
    if (this.panelVisible) return;

    this.panelSelector.classList.add("fds-slide-out-panel--visible");
    this.panelSelector.setAttribute("aria-hidden", "false");

    if (this.panelSelector.classList.contains("fds-slide-out-panel")) {
      getHtmlElement().classList.add("fds-slide-out-visible");
    }

    getBodyScroll().classList.add("fds-body-scroll--with-slide-out");

    getBodyScroll().addEventListener("transitionend", () => {
      // When the transition has finished, disable transitions in case the window is resized
      getBodyScroll().style.transition = "none";
      this.panelSelector.style.transition = "none";
    }, { once: true });

    // Change focus to the panel's close link, so the user is focussed in the panel
    this.panelSelector.querySelectorAll(".fds-slide-out-panel__close")[0].focus();

    this.panelVisible = true;

    // Replace the fragment identifier in the URL
    if (window.history.replaceState) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(this.panelId, "true");
      this.historyReplace(searchParams);
    }
  }

  hidePanel(callback) {
    if (!this.panelVisible) return;

    getBodyScroll().style.transition = "";
    this.panelSelector.style.transition = "";
    this.panelSelector.classList.remove("fds-slide-out-panel--visible");
    this.panelSelector.setAttribute("aria-hidden", "true");
    getHtmlElement().classList.remove("fds-slide-out-visible");

    // Remove body scroll modifier class
    getBodyScroll().classList.remove("fds-body-scroll--with-slide-out");

    getBodyScroll().addEventListener("transitionend", () => {
      getBodyScroll().style.transition = "none";
      getBodyScroll().style.transition = "";
    }, { once: true });

    if (typeof callback === "function") callback();

    // Remove the transform so the panel slides back out of view
    this.panelSelector.style.transform = "";
    this.panelVisible = false;

    // Re-focus on the open slide out button once closed
    const openSlideOutButton = document.querySelector(`[data-open-slide-out-panel="${this.panelId}"]`);
    openSlideOutButton.focus();

    // Remove the fragment identifier in the URL
    if (window.history.replaceState) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(this.panelId);
      this.historyReplace(searchParams);
    }
  }

  historyReplace(searchParams) {
    const searchParamString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : "";
    const locationHash = window.location.hash.length > 0 ? `#${window.location.hash}` : "";
    window.history.replaceState(undefined, undefined, window.location.pathname + searchParamString + locationHash);
  }
}

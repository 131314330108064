import { removeContainerErrors, showError, UploadErrorMessages } from "./fileUploadUtils";
import FileUploadList from "./fileUploadList";

// File upload component
export default class FileUpload {
  constructor(module) {
    // DOM layout
    this.module = module;
    this.moduleContainer = this.module.closest("[data-module='fds-file-upload-container']");
    this.dropzone = this.module.closest("[data-module='fds-file-upload-dropzone']");

    // Features
    this.fileInputId = this.module.getAttribute("id");
    this.multiFileErrorMessage = this.module.getAttribute("data-multi-file-error-message");
    this.multipleUpload = this.module.hasAttribute("multiple");
    this.sequentialUploads = this.module.getAttribute("data-sequential-uploads");

    // Init a file upload list and its items
    this.uploadedFiles = new FileUploadList(this.module, this.moduleContainer);

    // Init event handlers
    this.dropzoneHandlers();
    this.navigationGuardHandler();

    // Init file upload plugin
    $(this.module).fileupload({
      dataType: "json",
      dropZone: $(this.dropzone),
      sequentialUploads: this.sequentialUploads,
      limitConcurrentUploads: this.sequentialUploads ? 1 : 2,
      add: (e, data) => { this.uploadedFiles.addHandler(data); },
      progress: (e, data) => { this.uploadedFiles.progressHandler(data); },
      done: (e, data) => { this.uploadedFiles.doneHandler(data); },
      fail: (e, data) => {
        this.uploadedFiles.removeFailedUpload(data, data.files[0].name);
        showError(this.moduleContainer, UploadErrorMessages.UPLOAD);
      },
      drop: (e, data) => this.dropzoneMultipleOrErrors(data),
    });

    // If only single file uploads and a file already exists, don't show the dropzone
    if (!this.multipleUpload && this.moduleContainer.querySelectorAll(".fds-file-upload-item").length > 0) {
      this.dropzone.classList.add("fds-file-upload-dropzone--hidden");
    }
  }

  /**
   * Setup handlers for the dropzone events
   * @private
   * */
  dropzoneHandlers() {
    const dropZoneText = ".fds-file-upload-dropzone__text";
    const dropZoneExtensions = ".fds-file-upload-dropzone__extensions";
    const dropZoneClass = "fds-file-upload-dropzone__content--hover";
    const dropZoneTextClass = "fds-file-upload-dropzone__text--hover";
    const dropZoneExtensionsClass = "fds-file-upload-dropzone__extensions--hover";
    const hasExtensions = (event) => event.target.querySelector(dropZoneExtensions);

    this.dropzone.addEventListener("dragover", event => {
      event.target.classList.add(dropZoneClass);
      event.target.querySelector(dropZoneText).classList.add(dropZoneTextClass);

      if (hasExtensions(event)) {
        event.target.querySelector(dropZoneExtensions)
          .classList.add(dropZoneExtensionsClass);
      }
    });
    this.dropzone.addEventListener("dragleave", event => {
      event.target.classList.remove(dropZoneClass);
      event.target.querySelector(dropZoneText).classList.remove(dropZoneTextClass);

      if (hasExtensions(event)) {
        event.target.querySelector(dropZoneExtensions)
          .classList.remove(dropZoneExtensionsClass);
      }
    });
    this.dropzone.addEventListener("drop", event => {
      event.target.classList.remove(dropZoneClass);
      event.target.querySelector(dropZoneText).classList.remove(dropZoneTextClass);

      if (hasExtensions(event)) {
        event.target.querySelector(dropZoneExtensions)
          .classList.remove(dropZoneExtensionsClass);
      }
    });

    /* Keyboard accessibility - we make the 'choose a file' link-styled label appear focused when the file input
       actually has focus. In IE, the space bar makes the file picker appear, but the enter key doesn't. So we intercept
       the keypress and call click() on the label instead. */
    this.dropzone.querySelector(".fds-file-upload-dropzone__link").addEventListener("keydown", event => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.target.previousElementSibling.click();
        event.preventDefault();
      }
    });
  }

  /**
   * Handles errors for multi/single file uploads
   * @param data - The uploaded file object
   * @private
   * */
  dropzoneMultipleOrErrors(data) {
    const multiErrorMessage = this.multiFileErrorMessage !== null ? this.multiFileErrorMessage.trim() : "";
    const errorMessage = `<p id="${this.fileInputId}-error" class="govuk-error-message" role="alert"><span class="govuk-visually-hidden">Error:</span> ${multiErrorMessage}</p>`;

    if (!this.multipleUpload && data.files.length > 1) {
      this.moduleContainer.querySelector(".fds-file-upload-dropzone__link-error").textContent = `Error: ${multiErrorMessage}`;
      this.moduleContainer.insertAdjacentHTML("afterbegin", errorMessage);
      this.moduleContainer.classList.add("govuk-form-group--error");
      this.moduleContainer.querySelector(".fds-file-upload-dropzone__link").focus();

      return false;
    }

    return removeContainerErrors(this.moduleContainer, this.fileInputId);
  }

  /**
   * Adds the navigation guard to stop accidental navigation
   */
  navigationGuardHandler() {
    // The "beforeunload" event is unreliable on mobile browsers. So we also attach an event handler onto the
    // form's submit - thereby targeting the most likely redirection a user will do.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
    // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006511-SW5

    const isUploadFileInProgress = () => document.querySelectorAll(".fds-file-upload-item__progress").length > 0;

    window.addEventListener("beforeunload", (event) => {
      if (isUploadFileInProgress()) {
        event.returnValue = "Changes that you made may not be saved.";
      }
    });

    if (this.moduleContainer.closest("form") !== null) {
      this.moduleContainer.closest("form").addEventListener("submit", (event) => {
        if (isUploadFileInProgress()) {
          event.preventDefault();
          this.uploadedFiles.modal.displayModal(this.formSubmitNavGuardHtml(), "Wait for the file to upload before continuing");
        }
      });
    }
  }

  /**
   * Generates the modal explaining that a file upload is occurring and that the user must wait.
   * */
  formSubmitNavGuardHtml() {
    return $(`
      <div class="fds-modal">
        <p class="govuk-body">Your file is still uploading. Wait for the upload to finish before continuing.</p>
      </div>
    `);
  }
}

export default class Modal {
  constructor() {
    this.overlayID = "fds-modal-overlay";
    this.contentID = "modal-content";
    this.hasModalClass = "fds-has-modal";
    this.closeClass = "fds-close-modal";
    this.templateClass = "modal-template";
    this.focusableElementsString = "a[href], "
      + "area[href], "
      + "input:not([disabled]), "
      + "select:not([disabled]),"
      + "textarea:not([disabled]),"
      + "button:not([disabled]),"
      + "iframe,"
      + "object,"
      + "embed,"
      + "*[tabindex],"
      + "*[contenteditable]";
    this.$originalFocusedElement = null;
  }

  /**
   * Displays a modal onscreen containing the content in $content
   *
   * @param $content The jQuery-wrapped element to display in the modal
   * @param ariaLabel The label for the modal, read out by screen readers but not shown onscreen
   * @param size The size of the modal. Is set to 'medium' by default
   */
  displayModal($content, ariaLabel, size = "medium") {
    // Store reference to currently focused element
    this.$originalFocusedElement = $(document.activeElement);

    // Remove any existing modal
    this.closeModal();

    // Build the new modal
    const $modal = $(`<div id="${this.overlayID}"> 
      <div id="modal" class="${size}-modal" role="dialog" aria-label="${ariaLabel}" aria-describedby="modal-aria-description"> 
      <div id="${this.contentID}" role="document"> 
      </div></div></div>`);
    const closePopoverButtonHidden = `<div
                                      id="modal-aria-description"
                                      class="govuk-visually-hidden">Press escape to close this popover</div>`;
    const closePopoverButton = `<button
                                id="fds-close-modal-fixed-button"
                                class="${this.closeClass} fds-link-button fds-link-button--inline"
                                data-module="govuk-button">Close</a>`;

    $modal.find(`#${this.contentID}`).append(closePopoverButtonHidden);
    $modal.find(`#${this.contentID}`).append(closePopoverButton);
    $modal.find(`#${this.contentID}`).append($content);

    // Add the class that stops scrolling of the main page
    $("html").addClass(this.hasModalClass);

    // Add the modal to the page
    $("body").append($modal);
    this.bindEvents();
    this.focusOnFirstElement();
  }

  /**
   * Close the modal
   */
  closeModal() {
    $(`#${this.overlayID}`).remove();
    $("html").removeClass(this.hasModalClass);
    this.unbindEvents();

    // Refocus on element that was focused when the modal opened (probably the link/button that triggered it)
    this.$originalFocusedElement.get(0).focus();
  }

  /**
   * Binds events associated with the modal
   * @private
   */
  bindEvents() {
    // Keydown
    $("body").on("keydown.FDS.modal", (event) => {
      // Escape key close modal
      if (event.which === 27) {
        this.closeModal();
      }
      // Trap the tab key
      if (event.which === 9) {
        this.trapTabKey(event);
      }
    });

    // If anything other than the modal gets focus (eg tabbing from address bar), force focus into the modal
    $(`body > *[id!="${this.overlayID}"]`).on("focusin.FDS.modal", (event) => {
      this.focusOnFirstElement();
      event.stopPropagation();
    });

    // Lets consumer define close links/buttons by giving them the class defined by closeClass
    $("body").on("click.FDS.modal", `.${this.closeClass}`, () => {
      this.closeModal();
      return false;
    });
  }

  /**
   * Unbinds all events associated with this modal set by bindEvents()
   * @private
   */
  unbindEvents() {
    $("*").off("FDS.modal");
  }

  /**
   * Sets focus to the first focusable element inside the modal
   * @private
   */
  focusOnFirstElement() {
    const elements = this.getFocusableElements();
    if (elements.length > 0) { elements[0].focus(); }
  }

  /**
   * Finds all of the focusable elements inside the modal
   * @returns {jQuery} collection of the focusable elements inside the modal
   * @private
   */
  getFocusableElements() {
    return $(`#${this.contentID}`).find("*").filter(`${this.focusableElementsString}`).filter(":visible");
  }

  /**
   * Ensures focus stays within the modal by forcing tab key to loop through elements in the modal
   * @param event keydown event
   * @private
   */
  trapTabKey(event) {
    const $focusableElms = this.getFocusableElements();
    const $focusedElm = $(document.activeElement);

    const focusedElmIndex = $focusableElms.index($focusedElm);

    if (event.shiftKey && focusedElmIndex === 0) {
      // If we're going backwards (shift-tab) and we're at the first focusable element,
      // loop back to last focusable element
      $focusableElms.get($focusableElms.length - 1).focus();
      event.preventDefault();
    } else if (!event.shiftKey && focusedElmIndex === $focusableElms.length - 1) {
      // If we're going forwards and we're at the last focusable element,
      // loop forwards to the first focusable element
      $focusableElms.get(0).focus();
      event.preventDefault();
    }
    // Otherwise, allow the tab to proceed as normal because we're still in the group of
    // focusable elements in the modal
  }
}

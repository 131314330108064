import escapeHtml from "escape-html";
import { CharacterCount } from "govuk-frontend";
import htmlToElement from "../../js/htmlToElement";
import Modal from "../modal/modal";
import {
  removeContainerErrors,
  showError,
  UploadErrorMessages,
  UploadErrorTypes,
} from "./fileUploadUtils";

export default class FileUploadList {
  constructor(module, moduleContainer) {
    // DOM layout
    this.module = module;
    this.moduleContainer = moduleContainer;
    this.dropzone = this.moduleContainer.querySelector("[data-module='fds-file-upload-dropzone']");

    // Container of files
    this.fileList = this.moduleContainer.querySelector("[data-module='fds-file-upload-list']");
    this.fileItem = undefined;
    this.fileIndex = this.fileList.querySelectorAll(".fds-file-upload-item").length;

    // Features
    this.fileInputId = this.module.getAttribute("id");
    this.formName = this.module.getAttribute("data-form-name");
    this.formFieldIdName = this.module.getAttribute("data-form-field-id-name");
    this.formFieldInstantName = this.module.getAttribute("data-form-field-instant-name");
    this.formFieldNameName = this.module.getAttribute("data-form-field-name-name");
    this.formFieldSizeName = this.module.getAttribute("data-form-field-size-name");
    this.formFieldDescriptionName = this.module.getAttribute("data-form-field-description-name");
    this.multipleUpload = this.module.hasAttribute("multiple");
    this.allowedExtensions = this.module.getAttribute("accept").split(",");
    this.maxsize = parseInt(this.module.getAttribute("upload-file-max-size"));
    this.fileDescription = this.module.getAttribute("data-file-description");
    this.showFileDescriptionCharacterCount = this.module.getAttribute("data-file-description-character-count");
    this.fileDescriptionMaxLength = this.module.getAttribute("data-file-description-maxlength");

    this.extensionError = `${UploadErrorMessages.EXTENSION} ${this.allowedExtensions.join(", ")}`;
    this.maxsizeError = `${UploadErrorMessages.MAX_SIZE} ${this.readableFileSizeString(this.maxsize)}`;

    // URLs
    this.downloadUrl = this.module.getAttribute("data-download-url");
    this.deleteUrl = this.module.getAttribute("data-delete-url");

    this.modal = new Modal();

    this.fileList.querySelectorAll("[data-module='fds-file-upload-item']")
      .forEach(element => this.deleteFileHandler(element));
  }

  /**
   * Handles adding files. Setups the DOM and checks for size and extensions.
   * @param data - The uploaded file object
   * */
  addHandler(data) {
    const filename = data.files[0].name;
    const { size } = data.files[0];

    const fileItemHtml = `<div class="fds-file-upload-item"><div class="fds-file-upload-item__info">
        <div class="fds-file-upload-item__file-info-wrapper">
          <div class="fds-file-upload-item__filename"> </div> 
          <div class="fds-file-upload-item__extra-info"> </div>
        </div>
      </div></div>`;
    const progressText = `<div class="fds-file-upload-item__progress">- <span class="fds-file-upload-item__progress-value" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">0</span><span class="fds-file-upload-item__progress-unit">%</span></div>`;

    // Assign uploaded HTML to data file object
    // data persists throughout file upload plugin handlers
    data.context = htmlToElement(fileItemHtml);

    this.fileList.prepend(data.context);

    data.context.querySelector(".fds-file-upload-item__filename").textContent = filename;
    data.context.querySelector(".fds-file-upload-item__extra-info").textContent = `- ${this.readableFileSizeString(size)}`;
    data.context.querySelector(".fds-file-upload-item__file-info-wrapper")
      .insertAdjacentHTML("beforeend", progressText);

    removeContainerErrors(this.moduleContainer, this.fileInputId);

    if (size > this.maxsize) {
      this.removeFailedUpload(data, filename);
      showError(data.context, this.maxsizeError);
    } else if (
      !this.allowedExtensions.some((extension) => filename.toLowerCase()
        .endsWith(extension.trim().toLowerCase()))
    ) {
      this.removeFailedUpload(data, filename);
      showError(data.context, this.extensionError);
    } else {
      if (this.fileDescription === "true") {
        const fileDescriptionTextarea = this.showFileDescriptionCharacterCount === "true"
          ? `<textarea class="govuk-textarea govuk-textarea--file-upload-character-count govuk-textarea--file-upload govuk-js-character-count" rows="2" aria-describedby=""></textarea>
             <div class="govuk-hint govuk-character-count__message">You can enter up to ${this.fileDescriptionMaxLength} characters</div>`
          : `<textarea class="govuk-textarea govuk-textarea--file-upload-character-count govuk-textarea--file-upload" rows="2"></textarea>`;

        const fileDescriptionHtml =
            `<div class="govuk-form-group govuk-form-group--file-upload 
                ${this.showFileDescriptionCharacterCount === "true" ? 'govuk-character-count govuk-character-count--file-upload' : ''}"
                ${this.showFileDescriptionCharacterCount === "true" ? `data-module="govuk-character-count" data-maxlength="${this.fileDescriptionMaxLength}` : ''}
             ">
              <label class="govuk-label">File description <span class="govuk-visually-hidden">for ${filename}</span></label>
              ${fileDescriptionTextarea}
            </div>`;

        data.context.querySelector(".fds-file-upload-item__info").insertAdjacentHTML("beforeend", fileDescriptionHtml);
      }

      data.submitTimestamp = new Date().toISOString();
      data.submit();
    }
  }

  /**
   * Handles files when the uploaded completes. Inits remove modal, checks for errors, adds error messages,
   * gives hidden inputs post back values
   * @param data - The uploaded file object
   * */
  doneHandler(data) {
    const filename = data.files[0].name;

    // file-upload-spring-starter behaviour
    const { fileId, error } = data.result;
    if (!fileId && error) {
      this.removeFailedUpload(data, filename);
      showError(data.context, data.result.error);
      data.context.querySelector(".fds-file-upload-item__progress").remove();
      data.context.focus();
      this.fileIndex += 1;
      return;
    }

    // legacy behaviour
    const { valid, errorType } = data.result;
    if (!valid && (data.result.error === undefined)) {
      let errorMessage;

      switch (errorType) {
        case UploadErrorTypes.EXTENSION:
          errorMessage = this.extensionError;
          break;
        case UploadErrorTypes.MAX_SIZE:
          errorMessage = this.maxsizeError;
          break;
        case UploadErrorTypes.VIRUS:
          errorMessage = UploadErrorMessages.VIRUS;
          break;
        default:
          errorMessage = UploadErrorMessages.UPLOAD;
      }

      this.removeFailedUpload(data, filename);
      showError(data.context, errorMessage);

      data.context.querySelector(".fds-file-upload-item__progress").remove();
      data.context.focus();
      this.fileIndex += 1;
      return;
    }

    const uploadedFileIdInput = `<input type="hidden" name="${this.formName}[${this.fileIndex}].${this.formFieldIdName}" value="${fileId}"/>`;
    const uploadedFileInstantInput = `<input type="hidden" name="${this.formName}[${this.fileIndex}].${this.formFieldInstantName}" value="${data.submitTimestamp}"/>`;
    const uploadedFileNameInput = `<input type="hidden" name="${this.formName}[${this.fileIndex}].${this.formFieldNameName}" value="${filename}"/>`;

    // formatting is arguably Freemarkers responsibility, but it's already implemented here and helps keep things consistent
    const filesize = this.readableFileSizeString(data.files[0].size);
    const uploadedFileSizeInput = `<input type="hidden" name="${this.formName}[${this.fileIndex}].${this.formFieldSizeName}" value="${filesize}"/>`;

    data.context.setAttribute("data-fileId", fileId);
    data.context.setAttribute("data-delete-url", `${this.deleteUrl}${fileId}`);
    data.context.setAttribute("data-fileName", filename);

    if (this.fileDescription === "true") {
      data.context.querySelector("label").setAttribute("for", `${this.formName}[${this.fileIndex}].${this.formFieldDescriptionName}`);
      data.context.querySelector(".govuk-textarea--file-upload")
        .setAttribute("id", `${this.formName}[${this.fileIndex}].${this.formFieldDescriptionName}`);
      data.context.querySelector(".govuk-textarea--file-upload")
        .setAttribute("name", `${this.formName}[${this.fileIndex}].${this.formFieldDescriptionName}`);

      if (this.showFileDescriptionCharacterCount === "true") {
        data.context.querySelector(".govuk-textarea--file-upload")
          .setAttribute("aria-describedby", `${this.formName}[${this.fileIndex}].${this.formFieldDescriptionName}-info`);
        data.context.querySelector(".govuk-textarea--file-upload + .govuk-character-count__message")
          .setAttribute("id", `${this.formName}[${this.fileIndex}].${this.formFieldDescriptionName}-info`);

        // Re-init GOVUK js for character count functionality
        new CharacterCount(data.context.querySelector("[data-module='govuk-character-count']"), {});
      }
    }

    const filenameDownloadLink = `<a href="${this.downloadUrl + fileId}" class="govuk-link">${escapeHtml(filename)}</a>`;

    data.context.querySelector(".fds-file-upload-item__filename").textContent = "";
    data.context.querySelector(".fds-file-upload-item__filename").insertAdjacentHTML("beforeend", filenameDownloadLink);
    data.context.querySelector(".fds-file-upload-item__file-info-wrapper").insertAdjacentHTML("beforeend", this.deleteFileLinkHtml(filename));

    this.deleteFileHandler(data.context);

    data.context.insertAdjacentHTML("beforeend", uploadedFileIdInput);
    data.context.insertAdjacentHTML("beforeend", uploadedFileInstantInput);
    data.context.insertAdjacentHTML("beforeend", uploadedFileNameInput);
    data.context.insertAdjacentHTML("beforeend", uploadedFileSizeInput);

    // Hide the dropzone for single uploads
    if (!this.multipleUpload) {
      this.dropzone.classList.add("fds-file-upload-dropzone--hidden");
    }

    data.context.querySelector(".fds-file-upload-item__filename > .govuk-link").focus();
    data.context.querySelector(".fds-file-upload-item__progress").remove();
    data.context.focus();
    this.fileIndex += 1;
  }

  /**
   * Handles the progress text for uploading files.
   * @param data - The uploaded file object
   * */
  progressHandler(data) {
    const progress = Math.max(0, parseInt((data.loaded / data.total) * 100, 10) - 1);
    const progressText = progress.toString();

    data.context.querySelector(".fds-file-upload-item__progress-value")
      .setAttribute("aria-valuenow", progressText.toString());
    data.context.querySelector(".fds-file-upload-item__progress-value").textContent = progressText;
  }

  /**
   * When an upload fails client side - handle removing and error state
   * @param data - The uploaded file object
   * @param filename - The name of the file
   * */
  removeFailedUpload(data, filename) {
    const errorId = `${filename.replace("", "-").toLowerCase()}-error`;
    const errorMessage = `<p id="${errorId}" class="govuk-error-message fds-file-upload-item__error"></p>`;

    data.context.querySelector(".fds-file-upload-item__progress").remove();
    data.context.querySelector(".fds-file-upload-item__info")
      .insertAdjacentHTML("beforeend", errorMessage);
    data.context.querySelector(".fds-file-upload-item__file-info-wrapper")
      .insertAdjacentHTML("beforeend", this.deleteFileLinkHtml(filename));

    const link = data.context.querySelector(".fds-file-upload-item__delete-link");
    link.addEventListener("click", (event) => {
      event.preventDefault();
      data.context.remove();
      this.indexFileItems();
    });

    link.setAttribute("aria-describedby", `${errorId}`);
    data.context.classList.add("fds-file-upload-item--error");
    data.context.querySelector(".fds-file-upload-item__error").setAttribute("role", "alert");
    link.focus();
  }

  /**
   * Handle deleting files, re-indexing and error summary
   * @param fileId - The uploaded file id
   * */
  deleteFile(fileId) {
    const csrfInput = document.querySelector("input[name='_csrf']");
    const hasForm = (csrfInput !== null);
    const csrfToken = hasForm ? csrfInput.getAttribute("value") : "";

    const uploadedFile = this.moduleContainer.querySelector(`[data-fileId="${fileId}"]`);
    const deletedInput = uploadedFile.querySelector(".govuk-textarea");
    const deleteUrl = uploadedFile.getAttribute("data-delete-url");
    const removeErrorMessage = `<p class="govuk-error-message"><span class="govuk-visually-hidden">Error:</span> There was a problem removing this file.</p>`;

    $.post(deleteUrl, { _csrf: csrfToken })
      .done(() => {
        uploadedFile.remove();
        this.modal.closeModal();
        this.indexFileItems();

        if (this.fileDescription === "true") {
          // find all error summary links for elements we have removed
          $(`.govuk-error-summary__list li a[href="#${deletedInput.getAttribute("id")}"]`).closest("li").remove();
        }

        // if it's empty remove the complete summary element
        if ($(".govuk-error-summary__list li").length === 0) {
          $(".govuk-error-summary").remove();
        }

        // Show the dropzone again when removing a single uploaded file
        if (!this.multipleUpload) {
          this.dropzone.classList.remove("fds-file-upload-dropzone--hidden");
        }
      })
      .fail(() => $(".fds-modal__filename").append(removeErrorMessage));
  }

  /**
   * Re-index uploaded files when a file gets removed
   * Prevents gaps in the list of files sent
   * */
  indexFileItems() {
    const fileItems = [...this.fileList.querySelectorAll(".fds-file-upload-item")];
    this.fileIndex = fileItems.length;

    fileItems.reverse().forEach((element, index) => {
      const attrValue = `${this.formName}[${index}].`;
      const textarea = element.querySelector(".govuk-textarea--file-upload");

      const fieldNames = [
        this.formFieldIdName,
        this.formFieldInstantName,
        this.formFieldNameName,
        this.formFieldSizeName,
      ];

      element.querySelectorAll("input").forEach(input => {
        const inputName = input.getAttribute("name");
        fieldNames.forEach(fieldName => {
          if (inputName.endsWith(fieldName)) {
            input.setAttribute("name", attrValue + fieldName);
          }
        });
      });

      if (this.fileDescription === "true") {
        element.querySelector("label").setAttribute("for", attrValue + this.formFieldDescriptionName);
        textarea.setAttribute("name", attrValue + this.formFieldDescriptionName);
        textarea.setAttribute("id", attrValue + this.formFieldDescriptionName);

        if (this.showFileDescriptionCharacterCount === "true") {
          textarea.setAttribute("aria-describedby", `${this.formName}[${index}].${this.formFieldDescriptionName}-info`);
          element.querySelector(".govuk-textarea--file-upload + .govuk-character-count__message")
            .setAttribute("id", `${this.formName}[${index}].${this.formFieldDescriptionName}-info`);
        }
      }
    });
  }

  deleteFileHandler(element) {
    element.querySelector(".fds-file-upload-item__delete-link").addEventListener("click", event => {
      event.preventDefault();
      const fileId = element.getAttribute("data-fileId");
      const fileName = element.getAttribute("data-fileName");

      this.modal.displayModal(this.deleteFileModalHtml(fileName), `Remove uploaded file ${escapeHtml(fileName)}`);

      document.querySelector("#fds-modal-overlay .fds-modal__confirm-button")
        .addEventListener("click", () => this.deleteFile(fileId));
    });
  }

  deleteFileModalHtml(filename) {
    return $(`<div class="fds-modal">
          <p class="govuk-body fds-modal__filename">Are you sure you want to remove ${escapeHtml(filename)}?</p>
          <div class="fds-modal__actions">
            <button data-module="govuk-button" class="fds-modal__confirm-button govuk-button">Remove</button>
            <button data-module="govuk-button" class="fds-modal__cancel-button fds-close-modal govuk-button govuk-button--secondary">Cancel</button>
          </div>
        </div>`);
  }

  deleteFileLinkHtml(filename) {
    return ` <a href="#" class="govuk-link fds-file-upload-item__delete-link">Remove file<span class="govuk-visually-hidden"> ${escapeHtml(filename)}</span></a>`;
  }

  /**
   * Transform the file size into a readable format e.g. 10kB
   * @param fileSizeInBytes - The size of the file in bytes
   * */
  readableFileSizeString(fileSizeInBytes) {
    if (fileSizeInBytes <= 0) {
      return "0 kB";
    }

    let i = -1;
    let calcFileSize = fileSizeInBytes;
    const byteUnits = [" kB", " MB", " GB", " TB", " PB", " EB", " ZB", " YB"];
    do {
      calcFileSize /= 1024;
      i += 1;
    } while (calcFileSize > 1024);

    return Math.max(calcFileSize, 1).toFixed() + byteUnits[i];
  }
}

/*
*   This software or document includes material copied from or derived from
*   https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/js/calendar-button.js.
*   Copyright © 2020 W3C® (MIT, ERCIM, Keio, Beihang).
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   File:   calendarButton.js
*/
export default class CalendarButtonInput {
  constructor(inputNode, buttonNode, datepicker) {
    this.inputNode = inputNode;
    this.buttonNode = buttonNode;
    this.imageNode = false;

    this.datepicker = datepicker;

    this.defaultLabel = this.buttonNode.innerText;

    this.keyCode = Object.freeze({
      ENTER: 13,
      SPACE: 32,
    });
  }

  init() {
    this.buttonNode.addEventListener("click", this.handleClick.bind(this));
    this.buttonNode.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.buttonNode.addEventListener("focus", this.handleFocus.bind(this));
  }

  handleKeyDown(event) {
    let flag = false;

    switch (event.keyCode) {
      case this.keyCode.SPACE:
      case this.keyCode.ENTER:
        this.datepicker.show();
        this.datepicker.setFocusDay();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleClick(event) {
    if (!this.datepicker.isOpen()) {
      this.datepicker.show();
      this.datepicker.setFocusDay();
    } else {
      this.datepicker.hide();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  setLabel(str) {
    const string = (typeof str === "string" && str.length) ? `, ${str}` : str;

    this.buttonNode.setAttribute("aria-label", this.defaultLabel + string);
  }

  setFocus() {
    this.buttonNode.focus();
  }

  setDate(day) {
    const zeroDay = this.padDate(day.getDate());
    const zeroMonth = this.padDate(day.getMonth() + 1);
    this.inputNode.value = `${zeroDay}/${zeroMonth}/${day.getFullYear()}`;
  }

  padDate(value) {
    return value.toString().padStart(2, "0");
  }

  getDate() {
    return this.inputNode.value;
  }

  getDateLabel() {
    let label = "";

    const parts = this.inputNode.value.split("/");

    if ((parts.length === 3)
      && Number.isInteger(parseInt(parts[0]))
      && Number.isInteger(parseInt(parts[1]))
      && Number.isInteger(parseInt(parts[2]))) {
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);

      label = this.datepicker.getDateForButtonLabel(year, month, day);
    }

    return label;
  }

  handleFocus() {
    const dateLabel = this.getDateLabel();

    if (dateLabel) {
      this.setLabel(`selected date is ${dateLabel}`);
    } else {
      this.setLabel("");
    }
  }
}
